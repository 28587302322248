import React, { useContext, useEffect } from 'react'
import { Link } from "react-router-dom";
import { Grid, ListItemText, ListItem, Divider, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import PageTitle from '../shared/PageTitle';
import { ReferenceContext } from '../../context/ReferenceContext';

const References: React.FC = () => {
    const { references, getReferences, deleteClient } = useContext(ReferenceContext);

    useEffect(() => {
        getReferences();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const deleteHandler = (id, e) => {
        e.preventDefault();
        deleteClient(id);
    }

    return (
        <Grid container>
            <PageTitle 
                name="References" 
                description="This is the list of references. By the click on one of the you will open it, and then you will be able to edit it." 
            />
            <Divider />
            <Grid item xs={12}>
                {references && references.map(item => {
                    const url = "/reference/" + item.id;
                    return(
                        <Link to={url} key={item.id}>
                            <ListItem button>
                                <ListItemText className="" primary={item.title} />
                                <IconButton edge="end" aria-label="delete" onClick={(e) => deleteHandler(item.id, e)}>
                                    <DeleteIcon />
                                </IconButton>
                            </ListItem>
                            <Divider />
                        </Link>
                    );
                })}
            </Grid>
        </Grid>
    )
}

export default References
