import React, { useContext, useState, useEffect } from 'react'
import { LoginContext } from '../../context/LoginContext';
import { Container, Grid, TextField, Box, Button, CircularProgress, Divider } from '@material-ui/core';
import Message from '../shared/Message';

const LoginForm: React.FC = () => {
    const { message, setMessage, isLoading, getLoginData } = useContext(LoginContext);

    const [ username, setUsername ] = useState("username");
    const [ password, setPassword ] = useState("none");

    const handleUsernameChange = event => setUsername(event.target.value);
    const handlePasswordChange = event => setPassword(event.target.value);

    useEffect(() => {
        // reset message
        setMessage("");
    })

    const handleSubmit = (event) => {
        event.preventDefault();

        const data = {
            username,
            password
        };

        getLoginData(data);
    }

    
    return (
        <div>
            <Container maxWidth="sm">
                <h1>Please Log In here.</h1>
                <Divider />
                <Message desc={message} />
                <form onSubmit={handleSubmit}>
                    <Grid container>
                        <Grid item xs={12}>
                            <TextField 
                                id="username" 
                                label="Username"
                                placeholder="Placeholder"
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                onChange={handleUsernameChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="password" 
                                label="Password"
                                type="password"
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                onChange={handlePasswordChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box pt={4}>
                                <Button variant="contained" color="primary" type="submit">
                                    Log in
                                    <Box pl={2} className={isLoading ? "" : "u-hide" }>
                                        <CircularProgress size={20} color="inherit" />
                                    </Box>
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        </div>
    )
}

export default LoginForm;
