import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Navigation from '../navigation/Navigation';
import TranslationAdd from '../translation/TranslationAdd';
import Translations from '../translation/Translations';
import Translation from '../translation/Translation';
import Footer from "../shared/Footer";
import References from '../reference/References';
import ReferenceAdd from '../reference/ReferenceAdd';
import Reference from '../reference/Reference';
import Photos from '../photos/Photos';
import PhotoAdd from '../photos/PhotoAdd';
import ContactForms from '../contact-forms/ContactForms';


const AppPage = () => {

    return (
        <BrowserRouter basename={'/'}>
            <Navigation />
            <Container maxWidth="lg">
                <Switch>
                    <Route exact path="/" component={References} />
                    <Route path="/contact-forms" component={ContactForms} />
                    <Route path="/references" component={References} />
                    <Route path="/reference/:id" component={Reference} />
                    <Route path="/reference-add" component={ReferenceAdd} />
                    <Route path="/photos" component={Photos} />
                    <Route path="/photo-add" component={PhotoAdd} />
                    <Route path="/translation-add" component={TranslationAdd} />
                    <Route path="/translations" component={Translations} />
                    <Route path="/translation/:id" component={Translation} />
                </Switch>
            </Container>
            <Footer />
        </BrowserRouter>
    )
}

export default AppPage
