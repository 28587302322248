import React from 'react';
import SettingsContextProvider from './context/SettingContext';
import LoginContextProvider from './context/LoginContext';
import LoginForm from './components/login/LoginForm';
import AppPage from './components/app/AppPage';
import TranslationContextProvider from './context/TranslationContext';

import "./style/main.scss";
import ReferenceContextProvider from './context/ReferenceContext';
import PhotosContextProvider from './context/PhotosContext';
import ContactFormContextProvider from './context/ContactFormContext';

const App: React.FC = () => {
  if (localStorage.getItem('token')) {
    return (
      <div className="App">
        <SettingsContextProvider>
          <LoginContextProvider>
            <TranslationContextProvider>
              <ReferenceContextProvider>
                <PhotosContextProvider>
                  <ContactFormContextProvider>
                    <AppPage />
                  </ContactFormContextProvider>
                </PhotosContextProvider>
              </ReferenceContextProvider>
            </TranslationContextProvider>
          </LoginContextProvider>
        </SettingsContextProvider>
      </div>
    )
  } else {
    return (
      <div className="App">
          <LoginContextProvider>
            <LoginForm />
          </LoginContextProvider>
      </div>
    )
  }
}

export default App;
