import React, { useState, useContext, useEffect } from 'react'
import { Container, Grid, TextField, Box, Button, CircularProgress } from '@material-ui/core';
import PageTitle from '../shared/PageTitle';
import Message from '../shared/Message';
import { ReferenceContext } from '../../context/ReferenceContext';

const ReferenceAdd: React.FC = () => {

    const { isLoading, message, setMessage, create } = useContext(ReferenceContext);

    const [title, setTitle] = useState("");
    const [location, setLocation] = useState("");
    const [description, setDescription] = useState("");

    const handleTitleChange = event => setTitle(event.target.value);
    const handleLocationChange = event => setLocation(event.target.value);
    const handleDescriptionChange = event => setDescription(event.target.value);

    useEffect(() => {
        setMessage("");
    });

    const handleSubmit = (event) => {
        event.preventDefault();

        const data = {
            title,
            location,
            description
        };
        
        create(data);
    }

    return (
        <Container maxWidth="md">
            <PageTitle 
                name="Add Reference" 
                description="Here you can add your project reference. Please add title and location." 
            />
            <Message desc={message} />
            <form onSubmit={handleSubmit}>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            id="title" 
                            label="Title"
                            placeholder="eg. Name of the project"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            onChange={handleTitleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="sub-title" 
                            label="Location"
                            placeholder="eg. Ausstellungsstraße 50, Wien"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            onChange={handleLocationChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="sub-title" 
                            label="Description"
                            placeholder="Project description if needed goes here"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            onChange={handleDescriptionChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box pt={4}>
                            <Button variant="contained" color="primary" type="submit">
                                Save
                                <Box pl={2} className={isLoading ? "" :  "u-hide"}>
                                    <CircularProgress size={20} color="inherit" />
                                </Box>
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </Container>
    )
}

export default ReferenceAdd
