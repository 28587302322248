import React, {useState, createContext } from "react";
import axios from "axios";
import { apiDomain } from "../utils/settings";

export const LoginContext = createContext(null);

const LoginContextProvider = (props: any) => {
    const [ isLogedIn, setIsLogedIn ] = useState(false);
    const [ message, setMessage ] = useState("");
    const [ isLoading, setIsLoading ] = useState(false)

    const getLoginData = (data) => {
        setIsLoading(true);
        axios.post(`${apiDomain}api/users/check.php`, { data })
        .then(res => {
            const item = res.data;
            if(item.status) {
                localStorage.setItem('token', item.token);
                setIsLogedIn(true);
                setMessage(item.message);
                setIsLoading(false);
                window.location.replace("/references");
            } else {
                setMessage(item.message);
                setIsLoading(false);
            }
        })
        .catch(error => {
            console.log(error)
        });
    }

    const removeToken = async (data) => {
        await axios.post(`${apiDomain}api/token/delete.php`, { data })
        .then(res => {
            if(res.data.status) {
                localStorage.removeItem('token');
                setIsLogedIn(false);
                window.location.reload();
            }
        })
        .catch(error => {
            console.log(error)
        });
    }

    return (
        <LoginContext.Provider value={{isLogedIn, isLoading, message, setMessage, getLoginData, removeToken}}>
            {props.children}
        </LoginContext.Provider>
    )
}

export default LoginContextProvider;