import React, {useState, createContext } from "react";
import axios from "axios";
import { apiDomain, userToken } from "../utils/settings";

export const ReferenceContext = createContext(null);

const ReferenceContextProvider = (props: any) => {

    const [ references, setReferences ] = useState([]);
    const [ reference, setReference ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ message, setMessage ] = useState("");
    const [ photos, setPhotos ] = useState([]);


    axios.defaults.headers.common['Authorization'] = `Bearer ${userToken}`
    
    const getReferences = async () => {
        setIsLoading(true);
        await axios.get(`${apiDomain}api/references/read.php`)
        .then(res => {
            const items = res.data;
            setReferences(items);
            setIsLoading(false);
        })
        .catch(error => {
            setMessage(error)
        });
    }

    const getReference = async (id) => {
        setIsLoading(true);
        await axios.get(`${apiDomain}api/references/readById.php?id=${id}`)
        .then(res => {
            const items = res.data;
            setReference(items);
            getConnectedPhotos(id);
            setIsLoading(false);
        })
        .catch(error => {
            setMessage(error)
        });
    }

    const getConnectedPhotos = async (id) => {
        setIsLoading(true);
        await axios.get(`${apiDomain}api/references/readConnectedPhotos.php?itemId=${id}`)
        .then(res => {
            const items = res.data;
            setPhotos(items);
            setIsLoading(false);
        })
        .catch(error => {
            setMessage(error)
        });
    }

    const create = async (data) => {
        setIsLoading(true);
        await axios.post(`${apiDomain}api/references/create.php`, { data })
        .then(res => {
            const item = res.data;
            // getReferences();
            setIsLoading(false);
            setMessage(item.message);
        })
        .catch(error => {
            setMessage(error)
        });
    }

    const updateReference = async (data) => {
        const id = data.id;
        setIsLoading(true);
        await axios.post(`${apiDomain}api/references/update.php`, { data })
        .then(res => {
            const item = res.data;
            getReference(id);
            setIsLoading(false);
            setMessage(item.message);
        })
        .catch(error => {
            setMessage(error)
        });
    }

    const deleteClient = async (id) => {
        setIsLoading(true);
        await axios.get(`${apiDomain}api/references/delete.php?id=${id}`)
        .then(res => {
            getReferences();
            setIsLoading(false);
        })
        .catch(error => {
            setMessage(error)
        });
    }

    const connectPhoto = async (id: number, photoId: number) => {
        setIsLoading(true);
        await axios.get(`${apiDomain}api/references/createConnectionPhotos.php?itemId=${id}&photoId=${photoId}`)
        .then(res => {
            // const item = res.data;
            getConnectedPhotos(id);
            setIsLoading(false);
        })
        .catch(error => {
            setMessage(error)
        });
    }

    const disconnectPhoto = async (id: number, photoId: number) => {
        setIsLoading(true);
        await axios.get(`${apiDomain}api/references/deleteConnectedPhoto.php?itemId=${id}&photoId=${photoId}`)
        .then(res => {
            // const item = res.data;
            getConnectedPhotos(id);
            setIsLoading(false);
        })
        .catch(error => {
            setMessage(error)
        });
    }

    const setMainPhoto = async (id: number, photoId: number) => {
        setIsLoading(true);
        await axios.get(`${apiDomain}api/references/createMainPhotoConnection.php?itemId=${id}&photoId=${photoId}`)
        .then(res => {
            getConnectedPhotos(id);
            setIsLoading(false);
        })
        .catch(error => {
            setMessage(error)
        });
    }

    return (
        <ReferenceContext.Provider value={{isLoading, references, reference, message, photos, setMessage, create, getReferences, deleteClient, updateReference, getReference, connectPhoto, disconnectPhoto, setMainPhoto}}>
            {props.children}
        </ReferenceContext.Provider>
    )
}

export default ReferenceContextProvider;