import React, { useContext, useEffect } from 'react'
import { Grid, Divider, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import PageTitle from '../shared/PageTitle';
import { PhotosContext } from '../../context/PhotosContext';
import { photosUrl } from '../../utils/settings';

const Photos: React.FC = () => {
    const { photos, getPhotos, deleteById } = useContext(PhotosContext);

    useEffect(() => {
        getPhotos();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const deleteHandler = (id) => {
        deleteById(id);
    }

    return (
        <Grid container>
            <PageTitle 
                name="Photos" 
                description="This is photo arhive. You can find all of them" 
            />
            <Divider />
            <Grid container spacing={2}>
                {photos && photos.map(item => {
                    return(
                        <Grid item xs={6} sm={3} key={item.id}>
                            <div className="c-photos__box">
                                <div className="c-photos__box-container">
                                    <img src={photosUrl + "thumb300/" + item.thumb} alt={item.description} />
                                </div>
                                <div className="c-photos__icon c-photos__icon--delete" onClick={() => deleteHandler(item.id)}>
                                    <Tooltip title="Delete this photo from photo archive">
                                        <DeleteIcon></DeleteIcon>
                                    </Tooltip>
                                </div>
                            </div>
                        </Grid>
                    )
                })}
            </Grid>
        </Grid>
    )
}

export default Photos
