import React, { useState, useContext, useEffect } from 'react'
import { Container, Grid, TextField, Box, Button, CircularProgress } from '@material-ui/core';
import { TranslationContext } from '../../context/TranslationContext';
import PageTitle from '../shared/PageTitle';
import Message from '../shared/Message';

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const ckeConfig = {
    toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'undo' ],
    heading: {
        options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
            { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' }
        ]
    }
}

const TranslationAdd: React.FC = () => {

    const { isLoading, message, setMessage, create } = useContext(TranslationContext);

    const [key, setKey] = useState("");
    const [content, setContent] = useState("<p>Please add some text here.</p>");

    useEffect(() => {
        setMessage("");
    })

    const handleSubmit = (event) => {
        event.preventDefault();

        const data = {
            key,
            content
        };
        create(data);
    }

    return (
        <Container maxWidth="md">
            <PageTitle 
                name="Translation key" 
                description="Here you can add new translation key. After adding it, it is ncessary to notify admin
                that you want to have new content on the website." 
            />
            <Message desc={message} />
            <form onSubmit={handleSubmit}>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            id="key" 
                            label="Key"
                            placeholder="eg. kontakt.tekst"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            onChange={event => setKey(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                    <CKEditor
                        editor={ ClassicEditor }
                        data = "Please add some content inside"
                        onInit={ editor => {
                            // You can store the "editor" and use when it is needed
                        } }
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            setContent(data);
                        } }
                        config = {ckeConfig}
                    />
                    </Grid>
                    <Grid item xs={12}>
                        <Box pt={4}>
                            <Button variant="contained" color="primary" type="submit">
                                Save
                                <Box pl={2} className={isLoading ? "" :  "u-hide"}>
                                    <CircularProgress size={20} color="inherit" />
                                </Box>
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </Container>
    )
}

export default TranslationAdd
