import React, { useEffect, useContext } from 'react'
import { Container, Grid } from '@material-ui/core';
import PageTitle from '../shared/PageTitle';
import { ContactFormContext } from '../../context/ContactFormContext';

export const ContactForms: React.FC = () => {

    const { contacts, selectAll } = useContext(ContactFormContext);

    useEffect(() => {
        selectAll();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    return (
        <Container maxWidth="md">
            <PageTitle 
                name="Contact Form" 
                description="This the place where you can find all the messages that people sent through contact form on the website" 
            />
            <Grid container spacing={2}>
                {contacts && contacts.map(item => {
                    return(
                        <div className="c-contacts__list-item">
                            <div className="u-fw--400">
                                Message sent by <span className="u-fw--700" >{item.email}</span> and the name of sender is: <span className="u-fw--700" >{item.name}</span>
                            </div>
                            <div className="u-fw--400">
                                Message: <span className="u-fw--700" >{item.message}</span> 
                            </div>
                            <div className="u-fw--400">
                                Message is sent on <span className="u-fw--700" >{item.date}</span> 
                            </div>
                        </div>
                    )
                })}
            </Grid>
        </Container>
    )
}

export default ContactForms;
