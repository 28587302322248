import React, { useContext, useState, useEffect } from 'react'
import { Container, Grid, TextField, Box, Button, CircularProgress } from '@material-ui/core'
import { TranslationContext } from '../../context/TranslationContext';
import { useParams } from 'react-router-dom';
import PageTitle from '../shared/PageTitle';
import Message from '../shared/Message';

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const Translation: React.FC = (props) => {

    const { isLoading, translation, updateTranslation, getTranslation, message, setMessage } = useContext(TranslationContext);
    
    const { id } = useParams();

    const [key, setKey] = useState("...loading");
    const [content, setContent] = useState("...loading");

    useEffect(() => {
        setMessage("");
        getTranslation(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        setKey(translation.key);
        setContent(translation.content);
    }, [translation])

    const handleSubmit = (event) => {
        event.preventDefault();

        const data = {
            id,
            key,
            content,
        };
        updateTranslation(data);
    }

    const ckeConfig = {
        toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'undo' ],
        heading: {
            options: [
                { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' }
            ]
        }
    }

    

    return (
        <Container maxWidth="md">
            <PageTitle 
                name="Translation keys" 
                description="In order to have everything up to date, and synced with the code on the website, please do not modify keys!" 
            />
            <Message desc={message} />
            <form onSubmit={handleSubmit}>
                <Grid container>
                    <Grid item xs={12}>
                    <TextField
                            id="key" 
                            label="Key"
                            placeholder="eg. contact.text.left"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            onChange={event => setKey(event.target.value)}
                            value={key == null ? "" : key}
                        />
                    </Grid>
                    <Grid item xs={12}>
                    <CKEditor
                        editor={ ClassicEditor }
                        data = {translation.content}
                        onInit={ editor => {
                            // You can store the "editor" and use when it is needed.
                        } }
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            setContent(data);
                        } }
                        config = {ckeConfig}
                    />
                    </Grid>
                    <Grid item xs={12}>
                        <Box pt={4}>
                            <Button variant="contained" color="primary" type="submit">
                                Save changes
                                <Box pl={2} className={isLoading ? "" :  "u-hide"}>
                                    <CircularProgress size={20} color="inherit" />
                                </Box>
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </Container>
    )
}


export default Translation
