import React, {useState, createContext } from "react";
import axios from "axios";
import { apiDomain, userToken } from "../utils/settings";

export const ContactFormContext = createContext(null);

const ContactFormContextProvider = (props: any) => {

    const [ contacts, setContacts ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ message, setMessage ] = useState("");


    axios.defaults.headers.common['Authorization'] = `Bearer ${userToken}`
    
    const selectAll = async () => {
        setIsLoading(true);
        await axios.get(`${apiDomain}api/email/read.php`)
        .then(res => {
            const items = res.data;
            setContacts(items);
            setIsLoading(false);
        })
        .catch(error => {
            setMessage(error)
        });
    }

    return (
        <ContactFormContext.Provider value={{isLoading, contacts, message, setMessage, selectAll}}>
            {props.children}
        </ContactFormContext.Provider>
    )
}

export default ContactFormContextProvider;