import React, {useState, createContext } from "react";
import axios from "axios";
import { apiDomain, userToken } from "../utils/settings";

export const TranslationContext = createContext(null);

const TranslationContextProvider = (props: any) => {

    const [ translations, setTranslations ] = useState([]);
    const [ translation, setTranslation ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ message, setMessage ] = useState("");


    axios.defaults.headers.common['Authorization'] = `Bearer ${userToken}`
    
    const getAllTranslations = async () => {
        setIsLoading(true);
        await axios.get(`${apiDomain}api/translation/read.php`)
        .then(res => {
            const items = res.data;
            setTranslations(items);
            setIsLoading(false);
        })
        .catch(error => {
            setMessage(error)
        });
    }

    const getTranslation = async (id) => {
        setIsLoading(true);
        await axios.get(`${apiDomain}api/translation/readById.php?id=${id}`)
        .then(res => {
            const items = res.data;
            setTranslation(items);
            setIsLoading(false);
        })
        .catch(error => {
            setMessage(error)
        });
    }

    const create = async (data) => {
        setIsLoading(true);
        await axios.post(`${apiDomain}api/translation/create.php`, { data })
        .then(res => {
            const item = res.data;
            // getAllTranslations();
            setIsLoading(false);
            setMessage(item.message);
        })
        .catch(error => {
            setMessage(error)
        });
    }

    const updateTranslation = async (data) => {
        const id = data.id;
        setIsLoading(true);
        await axios.post(`${apiDomain}api/translation/update.php`, { data })
        .then(res => {
            const item = res.data;
            getTranslation(id);
            setIsLoading(false);
            setMessage(item.message);
        })
        .catch(error => {
            setMessage(error)
        });
    }

    const deleteTranslation = async (id) => {
        setIsLoading(true);
        await axios.get(`${apiDomain}api/translation/delete.php?id=${id}`)
        .then(res => {
            getAllTranslations();
            setIsLoading(false);
        })
        .catch(error => {
            setMessage(error)
        });
    }

    return (
        <TranslationContext.Provider value={{isLoading, translations, translation, message, setMessage, create, getAllTranslations, deleteTranslation, updateTranslation, getTranslation}}>
            {props.children}
        </TranslationContext.Provider>
    )
}

export default TranslationContextProvider;