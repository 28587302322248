import React, { useContext, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { Container, Grid, TextField, Box, Button, CircularProgress, Tooltip } from '@material-ui/core';
import PageTitle from '../shared/PageTitle';
import Message from '../shared/Message';
import { ReferenceContext } from '../../context/ReferenceContext';
import { PhotosContext } from '../../context/PhotosContext';
import { photosUrl } from '../../utils/settings';
import AddToPhotos from '@material-ui/icons/AddToPhotos';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import StarBorder from '@material-ui/icons/StarBorder';
import StarRate from '@material-ui/icons/StarRate';

const Reference: React.FC = () => {
    const { photos, isLoading, reference, updateReference, getReference, message, setMessage, connectPhoto, disconnectPhoto, setMainPhoto } = useContext(ReferenceContext);
    const { searchedPhotos, searchByName } = useContext(PhotosContext);
    
    let { id } = useParams();

    const [title, setTitle] = useState("");
    const [location, setLocation] = useState("");
    const [description, setDescription] = useState("");
    const [search, setSearch] = useState("");

    useEffect(() => {
        setMessage("");
        getReference(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        setTitle(reference.title);
        setLocation(reference.location);
        setDescription(reference.description);
    }, [reference]);

    const handleSubmit = (event) => {
        event.preventDefault();

        const data = {
            id,
            title,
            location,
            description
        };
        updateReference(data);
    }

    const searchPhotos = (event) => {
        event.preventDefault();
        setSearch(event.target.value);
        searchByName(search);
    }

    const connectHandler = (photoId: number) => {
        connectPhoto(id, photoId);
    }

    const disconnectHandler = (photoId: number) => {
        disconnectPhoto(id, photoId);
    }

    const setAsMainHandler = (photoId: number) => {
        setMainPhoto(id, photoId);
    }

    return (
        <Container maxWidth="md">
            <PageTitle 
                name="Reference" 
                description="Here you are able to update data that is already added" 
            />
            <Message desc={message} />
            <form onSubmit={handleSubmit}>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            id="title" 
                            label="Title"
                            placeholder="eg. Project St. Marx"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            value={title == null ? "" : title}
                            onChange={event => setTitle(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="location" 
                            label="Location/Address"
                            placeholder="eg. Ausstellunsstraße 50"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            value={location == null ? "" : location}
                            onChange={event => setLocation(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="description" 
                            label="Project description"
                            placeholder="eg. This project is constructed by famous architecture"
                            fullWidth
                            multiline
                            rows={6}
                            margin="normal"
                            variant="outlined"
                            value={description == null ? "" : description}
                            onChange={event => setDescription(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box pt={4}>
                            <Button variant="contained" color="primary" type="submit">
                                Save
                                <Box pl={2} className={isLoading ? "" :  "u-hide"}>
                                    <CircularProgress size={20} color="inherit" />
                                </Box>
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </form>

            <Box pt={4}></Box>
            <PageTitle 
                name="Connected photos" 
                description="Bellow are listed photos that are connected with this reference." 
            />
            <Grid container spacing={2}>
                {photos && photos.map(item => {
                    return(
                        <Grid item xs={6} sm={3} key={item.id}>
                            <div className="c-photos__box">
                                <div className="c-photos__box-container">
                                    <img src={photosUrl + "thumb300/" + item.thumb} alt={item.description} />
                                </div>
                                <div className="c-photos__icon c-photos__icon--main" onClick={() => setAsMainHandler(item.id)}>
                                    <Tooltip title="Mark this photo as main">
                                        { item.main === '0' ? <StarBorder></StarBorder> : <StarRate></StarRate>}
                                    </Tooltip>
                                </div>
                                <div className="c-photos__icon c-photos__icon--delete" onClick={() => disconnectHandler(item.id)}>
                                    <Tooltip title="Remove photo from this reference">
                                        <RemoveCircle></RemoveCircle>
                                    </Tooltip>
                                </div>
                            </div>
                        </Grid>
                    )
                })}
            </Grid>
            
            <Box pt={4}></Box>
            <PageTitle 
                name="Search photo archive" 
                description="Bellow you can search photos in order to connect them with this reference." 
            />
            <Grid container>
                <form onSubmit={searchPhotos}>
                    <TextField
                        id="search" 
                        label="Search photos by name"
                        placeholder="eg. Wien 12"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        onChange={event => setSearch(event.target.value)}
                    />
                </form>
                <Grid container spacing={2}>
                    {searchedPhotos && searchedPhotos.map(item => {
                        return(
                            <Grid item xs={6} sm={3} key={item.id}>
                                <div className="c-photos__box">
                                    <div className="c-photos__box-container">
                                        <img src={photosUrl + "thumb300/" + item.thumb} alt={item.description} />
                                    </div>
                                    <div className="c-photos__icon c-photos__icon--add" onClick={() => connectHandler(item.id)}>
                                        <Tooltip title="Connect with this refference">
                                            <AddToPhotos></AddToPhotos>
                                        </Tooltip>
                                    </div>
                                </div>
                            </Grid>
                        )
                    })}
                </Grid>
            </Grid>
        </Container>
    )
}

export default Reference;
