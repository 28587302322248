import React, { useContext, useEffect } from 'react'
import { TranslationContext } from '../../context/TranslationContext';
import { Link } from "react-router-dom";
import { Grid, ListItemText, ListItem, Divider, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import PageTitle from '../shared/PageTitle';

const Translations: React.FC = () => {
    const { translations, getAllTranslations, deleteTranslation } = useContext(TranslationContext);

    useEffect(() => {
        getAllTranslations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const deleteHandler = (id, e) => {
        e.preventDefault();
        deleteTranslation(id);
    }

    return (
        <Grid container>
            <PageTitle 
                name="List of Translation keys" 
                description="This is the list of the keys on the website. Every key here is connected
                with some component on the website. By clicking on the certain key,
                you will be able to enter edit mode, and change the content of the translation key.
                It is not suggested to modify key because it is hardcoded in the website. Of course
                you are allowed to do anything you want with content of the key." 
            />
            <Divider />
            <Grid item xs={12}>
                {translations && translations.map(item => {
                    const url = "/translation/" + item.id;
                    return(
                        <Link to={url} key={item.id}>
                            <ListItem button>
                                <ListItemText className="" primary={item.key} />
                                <IconButton edge="end" aria-label="delete" onClick={(e) => deleteHandler(item.id, e)}>
                                    <DeleteIcon />
                                </IconButton>
                            </ListItem>
                            <Divider />
                        </Link>
                    );
                })}
            </Grid>
        </Grid>
    )
}

export default Translations
