import React, {useState, createContext } from "react";
import axios from "axios";
import { apiDomain, userToken } from "../utils/settings";

export const PhotosContext = createContext(null);

const PhotosContextProvider = (props: any) => {

    const [ photos, setPhotos ] = useState([]);
    const [ photo, setPhoto ] = useState([]);
    const [ searchedPhotos, setSearchedPhotos ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ message, setMessage ] = useState("");


    axios.defaults.headers.common['Authorization'] = `Bearer ${userToken}`
    
    const getPhotos = async () => {
        setIsLoading(true);
        await axios.get(`${apiDomain}api/photos/read.php`)
        .then(res => {
            const items = res.data;
            setPhotos(items);
            setIsLoading(false);
        })
        .catch(error => {
            setMessage(error)
        });
    }

    const getPhotosById = async (id) => {
        setIsLoading(true);
        await axios.get(`${apiDomain}api/photos/readById.php?id=${id}`)
        .then(res => {
            const items = res.data;
            setPhoto(items);
            setIsLoading(false);
        })
        .catch(error => {
            setMessage(error)
        });
    }

    const create = async (data) => {
        console.warn(data);
        setIsLoading(true);
        await axios.post(`${apiDomain}api/photos/create.php`, data, {
            onUploadProgress: ProgressEvent => {
                console.log("Upload progres: " + Math.round(ProgressEvent.loaded / ProgressEvent.total * 100) + "%" );
            }
        })
        .then(res => {
            const item = res.data;
            // getPhotos();
            setIsLoading(false);
            setMessage(item.message);
        })
        .catch(error => {
            setMessage(error)
        });
    }

    const updateName = async (data) => {
        setIsLoading(true);
        await axios.post(`${apiDomain}api/photos/update.php`, { data })
        .then(res => {
            const item = res.data;
            setIsLoading(false);
            setMessage(item.message);
        })
        .catch(error => {
            setMessage(error)
        });
    }

    const searchByName = async (title: string) => {
        setIsLoading(true);
        await axios.get(`${apiDomain}api/photos/readByName.php?search=${title}`)
        .then(res => {
            const item = res.data;
            setSearchedPhotos(item);
            getPhotos();
            setIsLoading(false);
        })
        .catch(error => {
            setMessage(error)
        });
    }

    const deleteById = async (id) => {
        setIsLoading(true);
        await axios.get(`${apiDomain}api/photos/delete.php?id=${id}`)
        .then(res => {
            const item = res.data;
            setMessage(item.message);
            getPhotos();
            setIsLoading(false);
        })
        .catch(error => {
            setMessage(error)
        });
    }

    return (
        <PhotosContext.Provider value={{isLoading, photos, photo, message, searchedPhotos, setMessage, create, getPhotos, getPhotosById, updateName, deleteById, searchByName}}>
            {props.children}
        </PhotosContext.Provider>
    )
}

export default PhotosContextProvider;