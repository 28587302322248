import React, { useState, useContext, useEffect } from 'react'
import { Container, Grid, TextField, Box, Button, CircularProgress } from '@material-ui/core';
import PageTitle from '../shared/PageTitle';
import Message from '../shared/Message';
import { PhotosContext } from '../../context/PhotosContext';

const PhotoAdd: React.FC = () => {

    const { isLoading, message, setMessage, create } = useContext(PhotosContext);

    const [title, setTitle] = useState("");
    const [selectedPhotos, setSelectedPhotos] = useState([]);

    const handleTitleChange = event => setTitle(event.target.value);
    const handlePhotosChange = event => {
        setSelectedPhotos(event.target.files);
    };

    useEffect(() => {
        setMessage("");
    });

    const handleSubmit = (event) => {
        event.preventDefault();

        const formData = new FormData();

        for (let i = 0; i < selectedPhotos.length; i++) {
            formData.append("photo_name[]", selectedPhotos[i], selectedPhotos[i].name);
        }
        formData.append("description", title);
        create(formData);
    }

    return (
        <Container maxWidth="md">
            <PageTitle 
                name="Photo Uploader" 
                description="Here you can add photos in the format JPG, JPEG, PNG, GIF." 
            />
            <Message desc={message} />
            <form onSubmit={handleSubmit}>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            id="title" 
                            label="Title"
                            placeholder="eg. Photo name"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            onChange={handleTitleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <input 
                            type="file" 
                            multiple 
                            onChange={handlePhotosChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box pt={4}>
                            <Button variant="contained" color="primary" type="submit">
                                Spremi
                                <Box pl={2} className={isLoading ? "" :  "u-hide"}>
                                    <CircularProgress size={20} color="inherit" />
                                </Box>
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </Container>
    )
}

export default PhotoAdd
